<template>
  <div>
    <div class="lg:hidden">
      <div class="px-6 py-2 flex flex-col text-text-primary lg:mt-32 lg:min-h-screen">
    <header class="py-2.5 text-lg flex text-text-alternate-1 pr-6">
      <button class="focus:outline-none" @click="$router.go(-1)">
        <LeftArrowIcon class="h-6 fill-current" />
      </button>
      <h2 class="mx-auto font-semibold">Top Blessors</h2>
    </header>
    <main>
      <div class="flex gap-4 mt-4 mb-6 justify-between">
        <BModalSelectSM v-model="type" :options="typeOptions" />
        <BModalSelectSM v-model="time" :options="timeOptions" />
      </div>
      <BPageLoader v-if="isLoading && !data.blessors?.length" />
      <div v-else-if="data.blessors.length > 0">
        <BBlessor
          v-for="(blessor, index) in data.blessors"
          :key="index"
          :blessor="blessor"
        />
        <BPageLoader v-if="isLoading" />
      </div>
      <div class="flex pt-20 flex-col items-center justify-center" v-else>
        <span class="p-6 bg-bg-alternate-3 rounded-full">
          <ResultEmptyIcon class="fill-current h-10" />
        </span>
        <h4 class="font-medium text-md mt-8">No Blessor Found</h4>
      </div>
    </main>
  </div>
    </div>
    <div class="hidden lg:flex justify-center pt-4">
      <div class="b-card mt-24 pt-8 pb-12 mb-20">
        <div class="px-26 py-2 flex flex-col text-text-primary ">
    <header class="py-2.5 text-lg flex text-text-alternate-1 pr-6">
      <button class="focus:outline-none" @click="$router.go(-1)">
        <LeftArrowIcon class="h-6 fill-current" />
      </button>
      <h2 class="mx-auto font-semibold">Top Blessors</h2>
    </header>
    <main>
      <div class="flex gap-4 mt-4 mb-6 justify-between">
        <BModalSelectSM v-model="type" :options="typeOptions" />
        <BModalSelectSM v-model="time" :options="timeOptions" />
      </div>
      <BPageLoader v-if="isLoading && !data.blessors?.length" />
      <div v-else-if="data.blessors.length > 0">
        <BBlessor
          v-for="(blessor, index) in data.blessors"
          :key="index"
          :blessor="blessor"
        />
        <BPageLoader v-if="isLoading" />
      </div>
      <div class="flex pt-20 flex-col items-center justify-center" v-else>
        <span class="p-6 bg-bg-alternate-3 rounded-full">
          <ResultEmptyIcon class="fill-current h-10" />
        </span>
        <h4 class="font-medium text-md mt-8">No Blessor Found</h4>
      </div>
    </main>
  </div>
      </div>
    </div>
  </div>
</template>

<script>
import BModalSelectSM from '@/components/ui/BModalSelectSM';
import BBlessor from '@/components/ui/BBlessor';
import { watch, toRefs, reactive } from 'vue';
const typeOptions = ['All', 'Individual', 'Corporate'];
const timeOptions = Array.from({ length: 6 }).map((_, index) => dayjs(dayjs().subtract(index, 'month')).format('MMMM YYYY'));
import { useApi } from '@/cmp-functions/useApi';
import dayjs from 'dayjs';
import { useInfiniteScroll } from '@/cmp-functions/useInfiniteScroll';
import { getTopBlesssors } from '@/services/api';
const typesMap = { Corporate: 'CORPORATE', Individual: 'INDIVIDUAL' };
const handlePaginationResponse = (pre, { data }) => {
  if (!data?.data) return pre;
  const { data: blessors, metadata } = data;

  return {
    blessors: [...pre.blessors, ...blessors],
    metaData: {
      offset: blessors.length + pre.blessors.length,
      ...metadata,
    },
  };
};

const getMonthYear = (time) => dayjs(`1 ${time}`).format('M/YYYY').split('/');

export default {
  name: 'TopBlessors',

  components: { BModalSelectSM, BBlessor },
  setup() {
    const inputs = reactive({
      type: typeOptions[0],
      time: timeOptions[0],
    });

    const [response, fetchTopBlesssors] = useApi(
      getTopBlesssors,
      {
        blessors: [],
        metaData: { offset: 0, isLastPage: false },
      },
      handlePaginationResponse
    );

    const requestForTopBlessors = ({ type, time }) => {
      response.data.blessors = [];
      const [month, year] = getMonthYear(time);
      fetchTopBlesssors({
        month,
        year,
        blessorType: typesMap[type],
        offset: 0,
      });
    };

    const fetchBlessorsData = () => {
      if (response.data.metaData.isLastPage || response.isLoading) return;
      const [month, year] = getMonthYear(inputs.time);
      fetchTopBlesssors({
        offset: response.data.metaData.offset,
        month,
        year,
        blessorType: typesMap[inputs.type],
      });
    };

    useInfiniteScroll(fetchBlessorsData, true);

    watch(inputs, requestForTopBlessors);

    return {
      ...toRefs(response),
      ...toRefs(inputs),
      typeOptions,
      timeOptions,
      dayjs,
    };
  },
};
</script>
